import i18next from "i18next";
import en from "./translations/en.json"
import { initReactI18next } from "react-i18next";

const langEn = "en";

const supportedLanguages = [langEn];

const configureI18n = () => {
  i18next.use(initReactI18next).init({
    supportedLngs: supportedLanguages,
    fallbackLng: langEn,
    defaultLanguage: langEn,
    resources: {
      en: {
        translation: en,
      },
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      skipOnVariables: false
    },
  });
};

export default configureI18n;
