import { Card, CardActions, CardContent, CardHeader, Chip, Typography, useTheme } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const textToTitleCase = (chipText) => {
  return chipText.split(" ").map((section) => section.charAt(0).toUpperCase() + section.slice(1)).join(" ");
};

const DataCard = ({ header, body, chipsText }) => {
  const [translate] = useTranslation();
  const theme = useTheme().palette;

  const getChipStyles = useCallback((chipText) => {
    switch (chipText) {
      case 'active':
      case 'confirmed':
      case 'completed':
        return {
          backgroundColor: theme.success.main,
          color: theme.success.contrastText,
        };
      case `${translate('dataCard.criticality')}: high`:
      case `${translate('dataCard.severityLabel')}: High`:
        return {
          backgroundColor: theme.error.main,
          color: theme.error.contrastText,
        };
      case `${translate('dataCard.criticality')}: moderate`:
      case `${translate('dataCard.severityLabel')}: Moderate`:
        return {
          backgroundColor: theme.warning.main,
          color: theme.warning.contrastText,
        };
      default:
        return {
          backgroundColor: theme.primary.main,
          color: theme.primary.contrastText,
        };
    };
  }, [theme, translate]);

  return (
    <Card sx={{ boxShadow: 0 }}>
      <CardHeader sx={{ padding: 0 }} title={
        <Typography variant="h6">
          {header}
        </Typography>
      }/>
      <CardContent sx={{ paddingX: 0, paddingY: '8px' }}>
        <Typography variant="body1">
          {body}
        </Typography>
      </CardContent>
      <CardActions sx={{ paddingX: 0, paddingY: '8px' }}>
        {chipsText.map((chip) => <Chip key={chip} label={textToTitleCase(chip)} sx={getChipStyles(chip)} />)}
      </CardActions>
    </Card>
  );
};

export default DataCard;