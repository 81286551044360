import { Stack } from "@mui/material";
import IPSSection from "./IPSSection";
import ViewSourceSection from "./ViewSourceSection";

const IPSSectionList = ({ sections }) => {
  return (
    <Stack gap={2} sx={{
      width: '100%',
      paddingBottom: '32px',
    }}>
      {sections.map((section) => {
        return <IPSSection key={section.title} title={section.title} entries={section.entry} />
      })}
      <ViewSourceSection />
    </Stack>
  );
};

export default IPSSectionList;
