import { Card, CardContent, CardHeader, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatDateAsText } from "../utils/dateUtil";

const PatientCard = ({ patientResource }) => {
  const theme = useTheme().palette;
  const [translate] = useTranslation();
  const detailsText = [
    {
      label: translate("patientCard.dateOfBirth"),
      value: formatDateAsText(patientResource.resource.birthDate),
    },
    {
      label: translate("patientCard.identifier"),
      value: `NB ${patientResource.resource.id}`,
    }
  ];

  return (
    <Card sx={{
      width: "100%",
      borderRadius: 3,
      backgroundColor: theme.common.white,
      padding: 0,
    }}>
      <CardHeader
        sx={{
          textAlign: 'center',
        }}
        title={patientResource.resource.name.map((name) => <Typography variant="h5" key={name}>{`${name.given.join(" ")} ${name.family}`}</Typography>)}
      />
      <CardContent sx={{
        paddingTop: 0,
        paddingX: '16px',
        // required to override default CardContent styling of settings the paddingBottom of the last child as 24px
        '&:last-child': { paddingBottom: '16px' }
      }}>
        <Stack
          direction="horizontal"
          justifyContent="center"
          divider={<Divider orientation="vertical" flexItem />}
        >
          {detailsText.map((entry) => (
            <Typography key={entry} component={"span"} sx={{
              textAlign: "center",
              flex: 1,
            }}>
              <Typography sx={{ color: theme.text.grey }} variant="subtitle2">
                {entry.label}
              </Typography>
              <Typography variant="body1">{entry.value}</Typography>
            </Typography>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PatientCard;
