import { Divider, Stack, Typography } from "@mui/material";
import MedicationRequestCard from "./resources/MedicationRequestCard";
import AllergyIntoleranceCard from "./resources/AllergyIntoleranceCard";
import ConditionCard from "./resources/ConditionCard";
import { useContext } from "react";
import { IPSContext } from "../Contexts";
import ResultsObservationCard from "./resources/ResultsObservationCard";
import VitalsObservationCard from "./resources/VitalsObservationCard";
import ImmunizationCard from "./resources/ImmunizationCard";
import Section from "./Section";
import { sortByDateFieldDescending } from "../utils/dateUtil";

const getCardForResource = (resource) => {
  switch (resource.resource.resourceType) {
    case "MedicationRequest":
      return <MedicationRequestCard key={resource} resource={resource} />;
    case "AllergyIntolerance":
      return <AllergyIntoleranceCard key={resource} resource={resource} />;
    case "Condition":
      return <ConditionCard key={resource} resource={resource} />;
    case "Observation":
      if (resource.resource.category[0].coding[0].code === 'laboratory') {
        return <ResultsObservationCard key={resource} resource={resource} />;
      } else if (resource.resource.category[0].coding[0].code === 'vital-signs') {
        return <VitalsObservationCard key={resource} resource={resource} />;
      } else {
        return <Typography>Unknown resource type!</Typography>;
      }
    case "Immunization":
      return <ImmunizationCard key={resource} resource={resource} />
    default:
      return <Typography>Unknown resource type!</Typography>;
  }
}

const IPSSection = ({ title, entries }) => {
  const ips = useContext(IPSContext);
  let resources = entries.map((entry) => ips.getResourceByReference(entry.reference));
  resources = sortByDateFieldDescending(resources);

  return (
    <Section
      title={title}
      details={
        <Stack
          sx={{
            padding: '16px',
            paddingTop: '8px',
          }}
          divider={
            <Divider orientation="horizontal" flexItem sx={{ marginY: 1 }}/>
          }
        >
          {resources.map((resource) => getCardForResource(resource))}
        </Stack>
      }
    />
  );
};

export default IPSSection;
