import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageLayout from "../layouts/PageLayout";

const ErrorPage = () => {
  const [translate] = useTranslation();

  return (
    <PageLayout>
      <Typography variant="body1">{translate('errorMessage')}</Typography>
    </PageLayout>
  );
};

export default ErrorPage;