import { Stack } from "@mui/material";

const PageLayout = ({ children }) => {
  return (
    <Stack gap={2} sx={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '16px',
    }}>
      {children}
    </Stack>
  )
};

export default PageLayout;