import { useContext } from "react";
import { IPSContext } from "../Contexts";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";
import Section from "./Section";

const ViewSourceSection = () => {
  const [translate] = useTranslation();
  const ips = useContext(IPSContext);

  return (
    <Section
      title={translate('viewSourceTitle')}
      details={(
        <ReactJson
          name={false}
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
          src={JSON.parse(JSON.stringify(ips.bundle))}
          indentWidth={2}
          style={{ fontSize: '0.8em', maxHeight: '600px', padding: '8px' }}
        />
      )}
      expanded={false}
    />
  );
};

export default ViewSourceSection;
