import { formatDateAsText } from "../../utils/dateUtil";
import DataCard from "./DataCard";
import LabelValueList from "../LabelValueList";
import { useTranslation } from "react-i18next";

const ImmunizationCard = ({ resource }) => {
  const [translate] = useTranslation();
  const fields = [
    {
      label: translate('dataCard.immunization.targetDiseasesLabel'),
      value: resource.resource.protocolApplied[0].targetDisease.map((disease) => disease.coding[0].display).join(", "),
    },
    {
      label: translate('dataCard.immunization.dateLabel'),
      value: formatDateAsText(resource.resource.occurrenceDateTime),
    },
    {
      label: translate('dataCard.immunization.lotNumberLabel'),
      value: resource.resource.lotNumber,
    },
    {
      label: translate('dataCard.dosageLabel'),
      value: `${resource.resource.doseQuantity.value} ${resource.resource.doseQuantity.unit}`
    },
    {
      label: translate('dataCard.immunization.manufacturerLabel'),
      value: resource.resource.manufacturer.display,
    },
  ];

  return (
    <DataCard
      header={resource.resource.vaccineCode.text}
      body={<LabelValueList fields={fields} />}
      chipsText={[resource.resource.status]}
    />
  )
};

export default ImmunizationCard;
