import "@fontsource/roboto"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const themeOptions = {
  palette: {
    background: {
      default: '#E3F2FD'
    },
    common: {
      white: "#FFFFFF",
    },
    primary: {
      main: '#2196F3',
      contrastText: '#FFFFFF',
      dark: '#002855',
    },
    text: {
      primary: '#000',
      secondary: '#FFFFFF',
      grey: '#00000099'
    },
    success: {
      main: '#2E7D32',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#D6A329',
      contrastText: '#FFFFFF'
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
};
