import { Container, ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes from "./routes";
import { themeOptions } from "./theme";
import configureI18n from "./i18n";
import PageHeader from "./components/PageHeader";

configureI18n();
const router = createBrowserRouter(routes);
const theme = createTheme(themeOptions);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} disableGutters>
        <PageHeader />
        <Container maxWidth="sm" disableGutters>
          <RouterProvider router={router} fallbackElement />
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default App;