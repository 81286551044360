import ErrorPage from "./pages/ErrorPage";
import IPSPage, { loader as ipsLoader } from "./pages/IPSPage";

const routes = [
  {
    path: "/shlink:/:shl",
    element: <IPSPage />,
    errorElement: <ErrorPage />,
    loader: ipsLoader,
  },
];

export default routes;