import { useContext } from "react";
import { IPSContext } from "../../Contexts";
import DataCard from "./DataCard";
import { useTranslation } from "react-i18next";
import { formatDateAsText } from "../../utils/dateUtil";
import LabelValueList from "../LabelValueList";

const ResultsObservationCard = ({ resource }) => {
  const [translate] = useTranslation();
  const ips = useContext(IPSContext);
  const specimen = ips.getResourceByReference(resource.resource.specimen.reference);
  const valueToDisplay = resource.resource.valueQuantity.value % 1 === 0 ? `${resource.resource.valueQuantity.value}.0` : resource.resource.valueQuantity.value

  const fieldsToDisplay = [
    {
      label: translate('dataCard.observation.dateLabel'),
      value: formatDateAsText(resource.resource.effectiveDateTime),
    },
    {
      label: translate('dataCard.observation.specimenLabel'),
      value: specimen.resource.identifier[0].value,
    },
    {
      label: translate('dataCard.observation.resultLabel'),
      value: `${valueToDisplay} ${resource.resource.valueQuantity.unit}`,
    },
    {
      label: translate('dataCard.observation.rangeLabel'),
      value: `${resource.resource.referenceRange[0].text} ${resource.resource.valueQuantity.unit}`,
    },
  ];

  return (
    <DataCard
      header={resource.resource.code.text}
      body={<LabelValueList fields={fieldsToDisplay} />}
      chipsText={[resource.resource.status]}
    />
  )
};

export default ResultsObservationCard;
