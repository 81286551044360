import { useContext } from "react";
import { IPSContext } from "../../Contexts";
import DataCard from "./DataCard";
import { useTranslation } from "react-i18next";
import { formatDateAsText } from "../../utils/dateUtil";
import LabelValueList from "../LabelValueList";

const MedicationRequestCard = ({ resource }) => {
  const [translate] = useTranslation();
  const ips = useContext(IPSContext);
  const medicationResource = ips.getResourceByReference(resource.resource.medicationReference.reference);
  const fields = [
    {
      label: translate('dataCard.medication.dateLabel'),
      value: formatDateAsText(resource.resource.authoredOn),
    },
    {
      label: translate('dataCard.dosageLabel'),
      value: resource.resource.dosageInstruction[0].text,
    },
  ];

  return (
    <DataCard
      header={medicationResource.resource.code.coding[0].display}
      body={<LabelValueList fields={fields} />}
      chipsText={[resource.resource.status]}
    />
  )
};

export default MedicationRequestCard;
