export const formatDateAsText = (date) => {
  if (!(date instanceof Date)) {
    if (!dateContainsTimeZone(date)) {
      date = addTimeZoneToDate(date);
    }
    date = new Date(date);
  }
  return (date).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })
};

const dateContainsTimeZone = (date) => {
  return date.length > 19;
};

const addTimeZoneToDate = (date) => {
  if (!dateContainsTimestamp(date)) {
    date = date + "T00:00:00";
  }
  const offset = new Date().getTimezoneOffset();
  let offsetInHours = (offset / 60);
  const symbol = offsetInHours < 0 ? "+" : "-"
  offsetInHours = Math.abs(offsetInHours);

  const formattedHoursOffset = String(Math.floor(offsetInHours)).padStart(2, "0");
  const formattedMinutesOffset = String((offsetInHours % 1) * 60).padStart(2, "0");
  const formattedTimezone = `${symbol}${formattedHoursOffset}:${formattedMinutesOffset}`
  return date + formattedTimezone;
};

const dateContainsTimestamp = (date) => {
  return date.length > 10;
};

export const sortByDateFieldDescending = (resources) => {
  switch (resources[0].resource.resourceType) {
    case "MedicationRequest":
      return resources.sort((a, b) => new Date(b.resource.authoredOn) - new Date(a.resource.authoredOn));
    case "AllergyIntolerance":
      return resources.sort((a, b) => new Date(b.resource.onsetDateTime) - new Date(a.resource.onsetDateTime));
    case "Condition":
      return resources.sort((a, b) => new Date(b.resource.onsetDateTime) - new Date(a.resource.onsetDateTime));
    case "Observation":
      return resources.sort((a, b) => new Date(b.resource.effectiveDateTime) - new Date(a.resource.effectiveDateTime));
    case "Immunization":
      return resources.sort((a, b) => new Date(b.resource.occurrenceDateTime) - new Date(a.resource.occurrenceDateTime));
    default:
      return resources;
  }
}
