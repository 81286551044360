import { useTranslation } from "react-i18next";
import DataCard from "./DataCard";
import LabelValueList from "../LabelValueList";

const AllergyIntoleranceCard = ({ resource }) => {
  const [translate] = useTranslation();
  const fields = [
    {
      label: '',
      value: `${resource.resource.type.charAt(0).toUpperCase() + resource.resource.type.slice(1)} ${translate('dataCard.allergyIntolerance.to')} ${resource.resource.category.join(", ")}`,
    },
    {
      label: translate('dataCard.onsetDate'),
      value: resource.resource.onsetDateTime,
    },
  ];

  return (
    <DataCard
      header={resource.resource.code.coding[0].display}
      body={<LabelValueList fields={fields} />}
      chipsText={[
        resource.resource.verificationStatus.coding[0].code,
        `${translate('dataCard.criticality')}: ${resource.resource.criticality}`,
      ]}
    />
  )
};

export default AllergyIntoleranceCard;
