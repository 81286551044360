import DataCard from "./DataCard";
import LabelValueList from "../LabelValueList";
import { useTranslation } from "react-i18next";
import { formatDateAsText } from "../../utils/dateUtil";

const VitalsObservationCard = ({ resource }) => {
  const [translate] = useTranslation();
  const fields = [
    {
      label: '',
      value: `${resource.resource.valueQuantity.value} ${resource.resource.valueQuantity.unit}`,
    },
    {
      label: translate('dataCard.effectiveDate'),
      value: formatDateAsText(resource.resource.effectiveDateTime),
    },
  ];

  return (
    <DataCard
      header={resource.resource.code.text}
      body={<LabelValueList fields={fields} />}
      chipsText={[resource.resource.status]}
    />
  )
};

export default VitalsObservationCard;
