import { useTranslation } from "react-i18next";
import DataCard from "./DataCard";
import LabelValueList from "../LabelValueList";

const ConditionCard = ({ resource }) => {
  const [translate] = useTranslation();
  const fields = [
    {
      label: translate('dataCard.activeProblems.dateLabel'),
      value: resource.resource.onsetDateTime,
    },
  ];

  const severityChipText = `${translate('dataCard.severityLabel')}: ${resource.resource.severity.coding[0].display}`

  return (
    <DataCard
      header={resource.resource.code.coding[0].display}
      body={<LabelValueList fields={fields} />}
      chipsText={[resource.resource.clinicalStatus.coding[0].code, severityChipText]}
    />
  )
};

export default ConditionCard;
