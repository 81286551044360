import { AppBar, Box, Stack, Typography, useScrollTrigger, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatDateAsText } from "../utils/dateUtil";
import { ReactComponent as VeroSourceLogo } from "../img/verosource-logo-white.svg";

const PageHeader = () => {
  const [translate] = useTranslation();
  const theme = useTheme().palette;
  const localDate = formatDateAsText(new Date());
  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });

  return (
    <>
      <AppBar position="sticky" elevation={trigger ? 4 : 0} sx={{ backgroundColor: theme.primary.dark, alignItems: 'center' }}>
        <Box maxWidth="sm" sx={{ textAlign: 'left', backgroundColor: theme.primary.dark, padding: 2, width: 'stretch' }}>
          <a href="https://verosource.com/" target="_blank" rel="noreferrer">
            <VeroSourceLogo width={130} height={39} />
          </a>
        </Box>
      </AppBar>
      <Stack
        sx={{
          textAlign: 'center',
          backgroundColor: theme.primary.dark,
          color: theme.text.secondary,
          width: '100%',
          paddingY: 1,
        }}
        gap={1}
      >
        <Typography variant="h5">{translate("ipsTitle")}</Typography>
        <Typography variant="body2">{`${translate("asOfSubtitle")} ${localDate}`}</Typography>
      </Stack>
    </>
    
  );
};

export default PageHeader;