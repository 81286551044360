import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from "@mui/material";

const Section = ({ title, details, expanded = true }) => {
  const theme = useTheme().palette;

  return (
    <Accordion 
      defaultExpanded={expanded}
      sx={{
        borderRadius: '8px',
        "& .Mui-expanded, &:first-child": {
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
        },
        "&:last-child": {
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
        },
        "& :not(.Mui-expanded)":{
          borderRadius: '8px',
        },
        "&:before": {
          display: 'none',
        },
        }}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: theme.text.secondary }} />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          backgroundColor: theme.primary.dark,
          color: theme.text.secondary,
        }}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{
        padding: 0,
        backgroundColor: theme.common.white,
        overflowX: 'auto',
      }}>
        {details}
      </AccordionDetails>
    </Accordion>
  );
};

export default Section;
