import { Stack } from "@mui/material";
import LabelValuePair from "./LabelValuePair";

const LabelValueList = ({ fields }) => {
  return (
    <Stack>
      {fields.map((field) => <LabelValuePair label={field.label} value={field.value} />)}
    </Stack>
  );
};

export default LabelValueList;
