import PatientCard from "../components/PatientCard";
import IPSSectionList from "../components/IPSSectionList";
import PageLayout from "../layouts/PageLayout";
import { useLoaderData } from "react-router-dom";
import { decode } from 'base-64';
import InternationalPatientSummary from "../model/InternationalPatientSummary";
import { IPSContext } from "../Contexts";

const loader = async ({ params: { shl } }) => {
  const shlPayload = JSON.parse(decode(shl));
  const { url } = shlPayload;
  const data = await import(`../data/${url}.json`);
  return new InternationalPatientSummary(data);
};

export { loader };

const IPSPage = () => {
  const ips = useLoaderData();

  return (
    <IPSContext.Provider value={ips}>
      <PageLayout>
        <PatientCard patientResource={ips.getPatientResource()}/>
        <IPSSectionList sections={ips.getSections()} />
      </PageLayout>
    </IPSContext.Provider>
  );
};

export default IPSPage;