class InternationalPatientSummary {
  bundle;

  constructor(bundle) {
    this.bundle = bundle;
  }

  getBundle() {
    return this.bundle;
  }

  getPatientResource() {
    return this.bundle.entry.find((resource) => resource.resource.resourceType === 'Patient');
  }

  getSections() {
    return this.bundle.entry.find((resource) => resource.resource.resourceType === 'Composition').resource.section;
  }

  getResourceByReference(reference) {
    const index = reference.indexOf('/');
    const resourceType = reference.substring(0, index);
    const id = reference.substring(index + 1);
    return this.bundle.entry.find((resource) => resource.resource.resourceType === resourceType && resource.resource.id === id);
  }
};

export default InternationalPatientSummary;