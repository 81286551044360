import { Stack, Typography } from "@mui/material";

const LabelValuePair = ({ label, value }) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {label && <Typography variant="subtitle2">{`${label}:`}</Typography>} 
      {value && <Typography variant="body1">{value}</Typography>}
    </Stack>
  );
};

export default LabelValuePair;
